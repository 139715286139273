import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../Components/layout';
import Hero from '../Components/hero';
import styled from 'styled-components';

const EulaContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  p {
    font-size: 16px;
    max-width: 800px;
  }
  table thead {
    display: none;
  }
  table thead tr,
  table tbody tr {
    background-color: transparent;
  }
  table td {
    border: none;
    padding-left: 0;
    line-height: 29px;
    font-size: 16px;
    &:first-child {
      min-width: 160px;
    }
  }
`;

class Eula extends React.Component {
  render() {
    let {allMarkdownRemark} = this.props.data;
    return (
      <Layout>
        <EulaContainer>
          <Hero
            content={
              allMarkdownRemark && allMarkdownRemark.edges && allMarkdownRemark.edges.length > 0
                ? allMarkdownRemark.edges.pop().node.html
                : ''
            }
            options={{bgColor: 'none'}}
          />
        </EulaContainer>
      </Layout>
    );
  }
}

export default Eula;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "eula"}}}) {
      edges {
        node {
          html
          frontmatter {
            id
          }
        }
      }
    }
  }
`;
